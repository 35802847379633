<template>
  <div id="app">
    <router-view/>
    <van-tabbar route class="tab-bottom" :placeholder="true">
      <van-tabbar-item replace to="/index" icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item replace to="/mine" icon="user-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload,
      goUrl: this.goUrl,
      backUrl: this.backUrl,
      showSize: this.showSize,
      addOne: this.addOne,
      update: this.update,
      delOne: this.delOne,
      getPmStatus: this.getPmStatus,
      getScope: this.getScope,
      isEdit: this.isEdit,
      selectName: this.selectName,
      isWorkorderAdmin: this.isWorkorderAdmin,
      isWorkorderMember: this.isWorkorderMember,
      dateTimeFormat: this.dateTimeFormat,
      dateFormat: this.dateFormat,
      parserDate: this.parserDate,
      getPmCategoryId: this.getPmCategoryId
    }
  },
  data() {
    return {
      isRouterAlive: true,
      messageTotal: null,
      hasNewMessage: false
    }
  },
  created() {
    window.localStorage.setItem('scanUrl',location.href.split('#')[0])
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },
    goUrl(url) {
      this.$router.push({
        path: url
      })
    },
    backUrl() {
      this.$router.go(-1)
    },
    showSize(base64url) {
      let str = base64url.replace('data:image/png;base64,', '')
      let equalIndex = str.indexOf('=')
      if (str.indexOf('=') > 0) {
        str = str.substring(0, equalIndex)
      }
      let strLength = str.length
      let fileLength = parseInt(strLength - (strLength / 8) * 2)
      // 由字节转换为kb
      let size = ''
      size = (fileLength / 1024).toFixed(2)
      let sizeStr = size + '' //转成字符串
      let index = sizeStr.indexOf('.') //获取小数点处的索引
      let dou = sizeStr.substr(index + 1, 2) //获取小数点后两位的值
      if (dou == '00') { //判断后两位是否为00，如果是则删除00
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
      }
      return size
    },
    addOne(API, thisData) {
      API.create(thisData).then((response) => {
        if (response.data.error_code === 1) {
          this.$notify({
            type: 'success',
            message: '恭喜你，新增成功'
          })
          this.backUrl()
        } else {
          this.$notify({type: 'danger', message: response.data.message})
        }
      })
    },
    update(API, thisData) {
      API.update(thisData).then(response => {
        if (response.data.error_code === 1) {
          this.$notify({
            type: 'success',
            message: '恭喜你，更新成功'
          })
          this.backUrl()
        } else {
          this.$notify({
            type: 'danger',
            message: response.data.message
          })
        }
      })
    },
    delOne(API, id, goBack) {
      this.$dialog.confirm({
        title: '提示',
        message: '此操作将永久删除该数据, 是否继续?'
      }).then(() => {
        API.delete(id).then((response) => {
          if (response.data.error_code === 1) {
            this.$notify({
              type: 'success',
              message: '恭喜你，删除成功'
            })
            if (goBack) {
              this.backUrl()
            } else {
              this.reload()
            }
          } else {
            this.$notify({
              type: 'danger',
              message: response.data.message
            })
          }
        })
      }).catch(() => {
        this.$notify({
          type: 'success',
          message: '已取消删除'
        })
      })
    },
    getPmStatus(row) {
      if (row.status === -1) {
        return "<span style='background: gray; color: #fff; font-size: 12px; padding: 0 3px; border-radius: 2px; margin-right: 6px;'>待派发</span>"
      }
      if (row.status === 1) {
        return "<span style='background: #1989fa; color: #fff; font-size: 12px; padding: 0 3px; border-radius: 2px; margin-right: 6px;'>待维修</span>"
      }
      if (row.status === 2) {
        return "<span style='background: #0cc15f; color: #fff; font-size: 12px; padding: 0 3px; border-radius: 2px; margin-right: 6px;'>完成</span>"
      }
      if (row.status === 10) {
        return "<span style='background: red; color: #fff; font-size: 12px; padding: 0 3px; border-radius: 2px; margin-right: 6px;'>超时</span>"
      }
      if (row.status === 20) {
        return "<span style='background: orange; color: #fff; font-size: 12px; padding: 0 3px; border-radius: 2px; margin-right: 6px;'>待班长审核</span>"
      }
      if (row.status === 30) {
        return "<span style='background: orange; color: #fff; font-size: 12px; padding: 0 3px; border-radius: 2px; margin-right: 6px;'>待工程师审核</span>"
      }
      if (row.status === 100) {
        return "<span style='background: #1989fa; color: #fff; font-size: 12px; padding: 0 3px; border-radius: 2px; margin-right: 6px;'>超时待维修</span>"
      }
      if (row.status === 200) {
        return "<span style='background: #0cc15f; color: #fff; font-size: 12px; padding: 0 3px; border-radius: 2px; margin-right: 6px;'>超时完成</span>"
      }
      if (row.status === 2000) {
        return "<span style='background: orange; color: #fff; font-size: 12px; padding: 0 3px; border-radius: 2px; margin-right: 6px;'>超时待班长审核</span>"
      }
      if (row.status === 3000) {
        return "<span style='background: orange; color: #fff; font-size: 12px; padding: 0 3px; border-radius: 2px; margin-right: 6px;'>超时待工程师审核</span>"
      }
    },
    getScope(id) {
      const all = [1, 60, 61]
      const monitorMember = [68, 69, 70, 71, 72, 78, 148, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176,
        177, 178, 179, 180, 197, 198, 199, 200, 205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218,
        233, 234, 235, 239, 240, 243, 244, 245]
      const tj = [295, 296, 297, 298, 299, 300, 301]
      const engineer = []
      if (all.includes(id)) {
        return 1 // 管理员
      } else if (monitorMember.includes(id)) {
        return 2 // 班长
      } else if (engineer.includes(id)) {
        return 3 // 工程师
      } else if (tj.includes(id)) {
        return 4 // 推进员
      } else {
        return 99 // 操作者
      }
    },
    isEdit() {
      return !isNaN(this.$route.path.split('/').pop())
    },
    selectName(arr, val) {
      const res = arr.find(item => {
        if(item.value === val) {
          return item
        }
      })
      if (res && res.name) {
        return res.name
      } else {
        return '暂无'
      }
    },
    isWorkorderAdmin(data, userId) {
      if (data.admin_id === this.userId) {
        return true
      } else if (data.other_member && data.other_member.length) {
        let b = false
        data.other_member.map( i => {
          if (i.admin.id === userId) {
            b = true
          }
        })
        return b
      } else {
        return false
      }
    },
    isWorkorderMember(data, userId) {
      if (data.other_member && data.other_member.length) {
        let b = false
        data.other_member.map( i => {
          if (i.admin.id === userId) {
            b = true
          }
        })
        return b
      } else {
        return false
      }
    },
    dateTimeFormat (date) {
      // 2015-03-19 12:00
      let d = new Date(parseInt(date))
      let year = d.getFullYear()
      let month = d.getMonth() + 1
      month = month < 10 ? '0' + month : month
      let day = d.getDate()
      day = day < 10 ? '0' + day : day
      let hour = d.getHours()
      hour = hour < 10 ? '0' + hour : hour
      let minute = d.getMinutes()
      minute = minute < 10 ? '0' + minute : minute
      let second = d.getSeconds()
      second = second < 10 ? '0' + second : second
      d = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second
      return d
    },
    dateFormat(date) {
      // 2015-03-19
      const y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? '0' + m : m
      let d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      return y + '-' + m + '-' + d
    },
    parserDate(date) {
      // Thu Mar 19 2015 12:00:00 GMT+0800 (中国标准时间)
      if (!isNaN(Date.parse(date))) {
        return new Date(Date.parse(date.replace(/-/g, "/")))
      } else {
        return new Date()
      }
    },
    getPmCategoryId() {
      const num = this.$route.name.split('pm')[1] - 1
      if (typeof num === 'number' && !isNaN(num)) {
        return num
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="less">
html{
  min-height: 100%;
  background-color: #ebeef5;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.tab-bottom{
  box-shadow:0px -5px 10px -10px #000;
}
#__vconsole{
  // display: none;
}
</style>
