import defaultSettings from '@/settings'

const title = defaultSettings.title || '北汽越野车移动端'

export default function getPageTitle(pageTitle){
    if (pageTitle) {
        return `${pageTitle} - ${title}`
    }
    return title
}
