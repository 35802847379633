import { login, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import router, { resetRouter } from '@/router'

const state = {
    token: getToken(),
    name: '',
    avatar: '',
    introduction: '',
    userId: '',
    roles: [],
    pmCanHandOut: '',
    pmCanMonitor: '',
    pmCanEngineer: '',
    cmCanBegin: '',
    cmCanMonitor: '',
    cmCanConfirm: '',
    dName: '',
    cName: '',
    jName: ''
}

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_INTRODUCTION: (state, introduction) => {
        state.introduction = introduction
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles
    },
    SET_USERID: (state, userId) => {
        state.userId = userId
    },
    SET_PMCANHANDOUT: (state, pmCanHandOut) => {
        state.pmCanHandOut = pmCanHandOut
    },
    SET_PMCANMONITOR: (state, pmCanMonitor) => {
        state.pmCanMonitor = pmCanMonitor
    },
    SET_PMCANENGINEER: (state, pmCanEngineer) => {
        state.pmCanEngineer = pmCanEngineer
    },
    SET_CMCANBEGIN: (state, cmCanBegin) => {
        state.cmCanBegin = cmCanBegin
    },
    SET_CMCANMONITOR: (state, cmCanMonitor) => {
        state.cmCanMonitor = cmCanMonitor
    },
    SET_CMCANCONFIRM: (state, cmCanConfirm) => {
        state.cmCanConfirm = cmCanConfirm
    },
    SET_DNAME: (state, dName) => {
        state.dName = dName
    },
    SET_CNAME: (state, cName) => {
        state.cName = cName
    },
    SET_JNAME: (state, jName) => {
        state.jName = jName
    }
}

const actions = {
    // user login
    login({ commit }, userInfo) {
        const { username, password } = userInfo
        return new Promise((resolve, reject) => {
            login({ username: username.trim(), password: password }).then(response => {
                const { data } = response
                commit('SET_TOKEN', data.token)
                setToken(data.token)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    },

    // get user info
    getInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            getInfo(state.token).then(response => {
                const { data } = response
                if (!data) {
                    reject('验证失败，请重新登录.')
                }

                const { roles, name, avatar, introduction, userId, pmCanHandOut, pmCanMonitor, pmCanEngineer,
                    cmCanBegin, cmCanMonitor, cmCanConfirm, dName, cName, jName } = data
                // roles must be a non-empty array
                if (!roles || roles.length <= 0) {
                    reject('getInfo: roles must be a non-null array!')
                }

                commit('SET_ROLES', roles)
                commit('SET_NAME', name)
                commit('SET_AVATAR', avatar)
                commit('SET_INTRODUCTION', introduction)
                commit('SET_USERID', userId)
                commit('SET_PMCANHANDOUT', pmCanHandOut)
                commit('SET_PMCANMONITOR', pmCanMonitor)
                commit('SET_PMCANENGINEER', pmCanEngineer)
                commit('SET_CMCANBEGIN', cmCanBegin)
                commit('SET_CMCANMONITOR', cmCanMonitor)
                commit('SET_CMCANCONFIRM', cmCanConfirm)
                commit('SET_DNAME', dName)
                commit('SET_CNAME', cName)
                commit('SET_JNAME', jName)
                resolve(data)
            }).catch(error => {
                reject(error)
            })
        })
    },

    // user logout
    logout({ commit }) {
        return new Promise((resolve, reject) => {
            try {
                commit('SET_TOKEN', '')
                commit('SET_ROLES', [])
                removeToken()
                resetRouter()
                resolve()
            } catch (e) {
                reject(e)
            }
        })
    },

    // remove token
    resetToken({ commit }) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            commit('SET_ROLES', [])
            removeToken()
            resolve()
        })
    },

    // dynamically modify permissions
    async changeRoles({ commit, dispatch }, role) {
        const token = role + '-token'

        commit('SET_TOKEN', token)
        setToken(token)

        const { roles } = await dispatch('getInfo')

        resetRouter()

        // generate accessible routes map based on roles
        const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
        // dynamically add accessible routes
        router.addRoutes(accessRoutes)

        // reset visited views and cached views
        dispatch('tagsView/delAllViews', null, { root: true })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
