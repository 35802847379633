import { createRouter, createWebHistory } from 'vue-router'

export const constantRoutes = [
  {
    path: '/login',
    name: 'login',
    meta: {
      'title': '登录'
    },
    component: () => import('@/views/login/index.vue'),
  }
]

export const asyncRoutes = [
  {
    path: '/async',
    name: 'home',
    meta: {
      title: 'EAM移动端'
    },
    component: () => import('@/views/index'),
    children: [
      {
        path: '/',
        redirect: '/index'
      },
      {
        path: '/index',
        name: 'index',
        meta: {
          title: '首页',
          roles: [16]
        },
        component: () => import('@/views/index/index.vue'),
      },
      {
        path: '/asset_base',
        name: 'asset_base',
        meta: {
          title: '资产管理',
          roles: [16]
        },
        component: () => import('@/views/asset/equipment_index.vue'),
        children: [
          {
            path: '/asset/asset',
            name: 'equipment',
            meta: {
              title: '资产查询',
              roles: [16]
            },
            component: () => import('@/views/asset/asset/index'),
          },
          {
            path: '/asset/equipment_detail/:id',
            name: 'equipment_detail',
            meta: {
              title: '设备详情',
              roles: [16]
            },
            component: () => import('@/views/asset/equipment/detail'),
          },
          {
            path: '/asset/jig_detail/:id',
            name: 'jig_detail',
            meta: {
              title: '工装详情',
              roles: [16]
            },
            component: () => import('@/views/asset/jig/detail'),
          }
        ]
      },
      {
        path: '/parts_base',
        name: 'parts_base',
        meta: {
          title: '备件管理',
          roles: [16]
        },
        component: () => import('@/views/stock/parts_index.vue'),
        children: [
          {
            path: '/parts/parts',
            name: 'parts',
            meta: {
              title: '备件查询',
              roles: [16]
            },
            component: () => import('@/views/stock/parts/index'),
          },
          {
            path: '/parts/parts_detail/:id',
            name: 'parts_detail',
            meta: {
              title: '备件详情',
              roles: [16]
            },
            component: () => import('@/views/stock/parts/detail'),
          }
        ]
      },
      {
        path: '/project_base',
        name: 'project_base',
        meta: {
          title: '维护管理',
          roles: [16]
        },
        component: () => import('@/views/project/apply_index.vue'),
        children: [
          {
            path: '/project/apply',
            name: 'apply',
            meta: {
              title: '报修管理',
              roles: [16]
            },
            component: () => import('@/views/project/apply/index')
          },
          {
            path: '/project/apply_add',
            name: 'apply_add',
            meta: {
              title: '新增报修',
              roles: [16]
            },
            component: () => import('@/views/project/apply/detail')
          },
          {
            path: '/project/apply_detail/:id',
            name: 'apply_detail',
            meta: {
              title: '查看报修',
              roles: [16]
            },
            component: () => import('@/views/project/apply/detail')
          }
        ]
      },
      {
        path: '/cm_base',
        name: 'cm_base',
        meta: {
          title: '报修管理',
          roles: [16]
        },
        component: () => import('@/views/workorder/order_index'),
        children: [
          {
            path: '/workorder/cm',
            name: 'cm',
            meta: {
              title: '故障维修',
              roles: [16]
            },
            component: () => import('@/views/workorder/cm/index'),
          },
          {
            path: '/workorder/cm_detail/:id',
            name: 'cm_detail',
            meta: {
              title: '维修进度',
              roles: [16]
            },
            component: () => import('@/views/workorder/cm/detail'),
          }
        ]
      },
      {
        path: '/pm_base',
        name: 'pm_base',
        meta: {
          title: '计划性工单管理',
          roles: [16]
        },
        component: () => import('@/views/workorder/pm_index'),
        children: [
          {
            path: '/workorder/pm1',
            name: 'pm1',
            meta: {
              title: '日常点检',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm1'),
          },
          {
            path: '/workorder/pm1_detail/:id',
            name: 'pm1_detail',
            meta: {
              title: '日常点检',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm1/detail'),
          },
          {
            path: '/workorder/pm2',
            name: 'pm2',
            meta: {
              title: '周期巡检',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm2'),
          },
          {
            path: '/workorder/pm2_detail/:id',
            name: 'pm2_detail',
            meta: {
              title: '周期巡检',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm2/detail'),
          },{
            path: '/workorder/pm3',
            name: 'pm3',
            meta: {
              title: '完好鉴定',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm3'),
          },
          {
            path: '/workorder/pm3_detail/:id',
            name: 'pm3_detail',
            meta: {
              title: '完好鉴定',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm3/detail'),
          },{
            path: '/workorder/pm4',
            name: 'pm4',
            meta: {
              title: '加油润滑',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm3'),
          },
          {
            path: '/workorder/pm4_detail/:id',
            name: 'pm4_detail',
            meta: {
              title: '加油润滑详情',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm3/detail'),
          },{
            path: '/workorder/pm5',
            name: 'pm5',
            meta: {
              title: '定期保养',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm3'),
          },
          {
            path: '/workorder/pm5_detail/:id',
            name: 'pm5_detail',
            meta: {
              title: '定期保养详情',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm3/detail'),
          },{
            path: '/workorder/pm6',
            name: 'pm6',
            meta: {
              title: '预防维修',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm3'),
          },
          {
            path: '/workorder/pm6_detail/:id',
            name: 'pm6_detail',
            meta: {
              title: '预防维修详情',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm3/detail'),
          },{
            path: '/workorder/pm7',
            name: 'pm7',
            meta: {
              title: '精度检测',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm3'),
          },
          {
            path: '/workorder/pm7_detail/:id',
            name: 'pm7_detail',
            meta: {
              title: '精度检测详情',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm3/detail'),
          },{
            path: '/workorder/pm8',
            name: 'pm8',
            meta: {
              title: '清洗换油',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm3'),
          },
          {
            path: '/workorder/pm8_detail/:id',
            name: 'pm8_detail',
            meta: {
              title: '清洗换油详情',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm3/detail'),
          },
          {
            path: '/workorder/pm9',
            name: 'pm9',
            meta: {
              title: '计划维修',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm9'),
          },
          {
            path: '/workorder/pm9_detail/:id',
            name: 'pm9_detail/:id',
            meta: {
              title: '计划维修详情',
              roles: [16]
            },
            component: () => import('@/views/workorder/pm9/detail'),
          }
        ]
      },
      {
        path: '/mine',
        name: 'mine',
        meta: {
          title: '个人中心',
          roles: [16]
        },
        component: () => import('@/views/mine/mine.vue'),
      }
    ]
  },
]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: constantRoutes,
})

export function resetRouter() {
  const newRouter = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: constantRoutes
  })
  router.matcher = newRouter.matcher // reset router
}

export default router
