import router from './router'
import store from './store'
import { Notify } from 'vant'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login']

router.beforeEach(async (to, from, next) => {
    NProgress.start()
    // await store.dispatch('user/resetToken')
    document.title = getPageTitle(to.meta.title)

    const hasToken = getToken()

    if (hasToken) {
        if (to.path === '/login') {
            NProgress.done()
            next({path: '/index'})
        } else {
            const hasRoles = store.getters.roles && store.getters.roles.length > 0
            if (hasRoles) {
                next()
            } else {
                try {

                    const { roles } = await store.dispatch('user/getInfo')
                    const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
                    accessRoutes.forEach((e) => {
                        router.addRoute(e)
                    })
                    next({ ...to, replace: true })
                } catch {
                    await store.dispatch('user/resetToken')
                    Notify({type: 'danger', message: '错误'})
                    next({path: `/login?redirect=${to.path}`})
                }
            }
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
            NProgress.done()
        } else {
            next({path: `/login?redirect=${to.path}`})
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})
