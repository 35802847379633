import axios from "axios"
import { Notify } from "vant"
import { Dialog } from "vant"
import store from '@/store'
import { getToken } from '@/utils/auth'

// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 15000

})

// 拦截器
service.interceptors.request.use(config => {
    if (store.getters.token) {
        config.headers['token'] = getToken()
    }
    return config
},error => {
    console.log(error)
    return Promise.reject(error)
})

service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        if (res.code !== 200) {
            const errMsg = res.message || '请求失败'
            console.log(res)
            Notify({
                message: errMsg,
                type: 'danger'
            })

            if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                Dialog.confirm({
                    title: '提示',
                    message: '登录超时'
                }).then(() => {
                    this.$router.push({
                        path: '/'
                    })
                })
            }
            return Promise.reject(new Error(errMsg))
        } else {
            return res
        }
    },
    error => {
        console.log({ error }) // for debug
        Notify({
            message: error.message,
            type: 'danger'
        })
        return Promise.reject(error)
    }
)

export default service
