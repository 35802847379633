const getters = {
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    name: state => state.user.name,
    introduction: state => state.user.introduction,
    userId: state => state.user.userId,
    roles: state => state.user.roles,
    pmCanHandOut: state => state.user.pmCanHandOut,
    pmCanEngineer: state => state.user.pmCanEngineer,
    pmCanMonitor: state => state.user.pmCanMonitor,
    cmCanBegin: state => state.user.cmCanBegin,
    cmCanMonitor: state => state.user.cmCanMonitor,
    cmCanConfirm: state => state.user.cmCanConfirm,
    dName: state => state.user.dName,
    cName: state => state.user.cName,
    jName: state => state.user.jName,
}
export default getters
